import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import BodyDrawer from '../components/body-drawer';
import Pager from '../components/pager';
import PostPreview from '../components/post-preview';

const BlogList = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const { currentPage, numPages } = pageContext;
  return (
    <Layout>
      <BodyDrawer>
        {posts.map(({ node }) => {
          return <PostPreview key={node.id} data={node} />;
        })}
      </BodyDrawer>
      <Pager currentPage={currentPage} numPages={numPages} />
    </Layout>
  );
};

export default BlogList;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip) {
      ...markdownRemarkEdge
    }
  }
`;
